import "./Page3.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import ContactUs from "components/ContactUs";
import { useEffect } from "react";
import Page3HeroSection from "./Page3HeroSection/Page3HeroSection";
// import HealthcareHeroSection from "../Healthcare/HealthcareHeroSection";
import Page3Features from "./Page3Features/Page3Features";
import Page3Discover from "./Page3Discover/Page3Discover";
import Page3Service from "./Page3Service/Page3Service";
import Page3Work from "./Page3Work/Page3Work";
import Page3Screenshot from "./Page3Screenshot";
import DigitizeYourBusiness from "./DigitizeYourBusiness";
// import Page3Testimonial from "./Page3Testimonial";
const Page3 = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="page3-container">
      <Page3HeroSection />
      <Page3Features />
      <Page3Discover />
      <Page3Service />
      <DigitizeYourBusiness />
      <Page3Screenshot />
      <Page3Work />
      {/* <Page3Testimonial /> */}
      <ContactUs />
    </div>
  );
};

export default Page3;
