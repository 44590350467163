import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/style.scss";
import Home from "pages/Home";
import About from "pages/About";
import Career from "pages/Career";
import TochModal from "components/modals/GetInTochModal";
import JobDetails from "pages/Career/Vacancy/JobDetails";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsOfService from "pages/TermsOfService";
import Layout from "pages/Layout";
import ErrorPopup from "components/ErrorPopup";
import NewVideoModal from "components/modals/NewVideoModal";
import "bootstrap/dist/js/bootstrap.min.js";
import HirePhpDeveloper from "pages/HirePhpDeveloper";
import HireUIUXDeveloper from "pages/HireUIUXDeveloper";
import HireFlutterDeveloper from "pages/HireFlutterDeveloper";
import HireVueJsDeveloper from "pages/HireVueJSDeveloper";
import HireFrontendDeveloper from "pages/HireFrontendDeveloper";
import HireBackendDeveloper from "pages/HireBackendDeveloper";
import HireFullstackDeveloper from "pages/HireFullstackDeveloper";
import HireAngularJSDeveloper from "pages/HireAngularJsDeveloper/HireAngularJsDeveloper";
import HireReactJSDeveloper from "pages/HireReactJSDeveloper/HireReactJSDeveloper";
import HireDedicatedDeveloper from "pages/HireDedicatedDeveloper";
import HireAndroidDeveloper from "pages/HireAndroidDeveloper";
import HireLaravelDeveloper from "pages/HireLaravelDeveloper";
import HireNodeJSDeveloper from "pages/HireNodeJSDeveloper";
import HireWordpressDeveloper from "pages/HireWordpressDeveloper";
import HireWebDesigner from "pages/HireWebDesigner";
import Culture from "pages/Culture";
import Blog from "pages/Blog";
import BlogDetail from "pages/Blog/BlogDetail/BlogDetail";
import BeautySalon from "pages/BusinessModels/BeautySalon";
import Healthcare from "pages/BusinessModels/Healthcare";
import Aos from "aos";
import "aos/dist/aos.css";
// import Page1 from "pages/BusinessModels/Page1";
// import Page02 from "pages/BusinessModels/Page-02";
import Page3 from "pages/BusinessModels/Page3/Page3";
// import Page04 from "pages/BusinessModels/Page-04";
// import Page7 from "pages/BusinessModels/page-7/Page_7";
// import SocialMediaApp from "pages/BusinessModels/SocialMediaApp";
// import Page8 from "pages/BusinessModels/Page-8";
// import Page9 from "pages/BusinessModels/Page9";
// import Page10 from "pages/BusinessModels/Page10";
// import Page12 from "pages/BusinessModels/Page12";
// import FoodDelivery from "pages/BusinessModels/FoodDelivery/FoodDelivery";
import RealEstate from "pages/BusinessModels/Real_Estate/Real_estate";
// import Real_estate from "pages/BusinessModels/Real_Estate/Real_estate";
import Freelancer from "pages/Freelancer";
function App() {
  const reduxData = useSelector((state) => state.global);
  const { isOpen, isVacancy, isVideo } = reduxData || {};

  useEffect(() => {
    Aos.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <BrowserRouter>
        <div className="pages-pera">
          <ErrorPopup />

          {isOpen && <TochModal />}
          {isVacancy && <JobDetails />}
          {/* {isVideo && <VideoModal />} */}
          {isVideo && <NewVideoModal />}

          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route
              path="/about"
              element={
                <Layout>
                  <About />
                </Layout>
              }
            />
            <Route
              path="/career"
              element={
                <Layout>
                  <Career />
                </Layout>
              }
            />
            <Route
              path="/life-sourcecube"
              element={
                <Layout>
                  <Culture />
                </Layout>
              }
            />
            <Route
              path="/blog"
              element={
                <Layout>
                  <Blog />
                </Layout>
              }
            />
            <Route
              path="/blog/blog-details/:id"
              element={
                <Layout>
                  <BlogDetail />
                </Layout>
              }
            />
            <Route
              path="/termsOfService"
              element={
                <Layout>
                  <TermsOfService />
                </Layout>
              }
            />
            <Route
              path="/privacyPolicy"
              element={
                <Layout>
                  <PrivacyPolicy />
                </Layout>
              }
            />
            <Route
              path="/hire-developer"
              element={
                <Layout>
                  <HireDedicatedDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-php-developer"
              element={
                <Layout>
                  <HirePhpDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-react-js-developer"
              element={
                <Layout>
                  <HireReactJSDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-ui-ux-developer"
              element={
                <Layout>
                  <HireUIUXDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-flutter-developer"
              element={
                <Layout>
                  <HireFlutterDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-angular-developer"
              element={
                <Layout>
                  <HireAngularJSDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-vue-developer"
              element={
                <Layout>
                  <HireVueJsDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-frontend-developer"
              element={
                <Layout>
                  <HireFrontendDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-backend-developer"
              element={
                <Layout>
                  <HireBackendDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-fullstack-developer"
              element={
                <Layout>
                  <HireFullstackDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-android-developer"
              element={
                <Layout>
                  <HireAndroidDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-laravel-developer"
              element={
                <Layout>
                  <HireLaravelDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-nodejs-developer"
              element={
                <Layout>
                  <HireNodeJSDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-nodejs-developer"
              element={
                <Layout>
                  <HireNodeJSDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-wordpress-developer"
              element={
                <Layout>
                  <HireWordpressDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-web-designer"
              element={
                <Layout>
                  <HireWebDesigner />
                </Layout>
              }
            />
            <Route
              path="/business-models/beauty-salon"
              element={
                <Layout>
                  <BeautySalon />
                </Layout>
              }
            />
            <Route
              path="/business-models/healthcare"
              element={
                <Layout>
                  <Healthcare />
                </Layout>
              }
            />
            <Route
              path="/business-models/on-demand-app"
              element={
                <Layout>
                  <Page3 />
                </Layout>
              }
            />
            {/* <Route
                            path="/business-models/page-1"
                            element={
                                <Layout>
                                    <Page1 />
                                </Layout>
                            }
                        />
                       
                        <Route
                            path="/business-models/page-4"
                            element={
                                <Layout>
                                    <Page04 />
                                </Layout>
                            }
                        />
                        <Route
                            path="/business-models/page-2"
                            element={
                                <Layout>
                                    <Page02 />
                                </Layout>
                            }
                        />

                        <Route
                            path="/business-models/page-7"
                            element={
                                <Layout>
                                    <Page7 />
                                </Layout>
                            }
                        />
                        <Route
                            path="/business-models/page-6"
                            element={
                                <Layout>
                                    <SocialMediaApp />
                                </Layout>
                            }
                        />
                        <Route
                            path="/business-models/page-8"
                            element={
                                <Layout>
                                    <Page8 />
                                </Layout>
                            }
                        />
                        <Route
                            path="/business-models/page-9"
                            element={
                                <Layout>
                                    <Page9 />
                                </Layout>
                            }
                        />
                        <Route
                            path="/business-models/page-10"
                            element={
                                <Layout>
                                    <Page10 />
                                </Layout>
                            }
                        />
                        <Route
                            path="/business-models/page-11"
                            element={
                                <Layout>
                                    <FoodDelivery />
                                </Layout>
                            }
                        /> */}

            <Route
              path="/business-models/real-estate"
              element={
                <Layout>
                  <RealEstate />
                </Layout>
              }
            />
            {/* <Route
                            path="/business-models/page-12"
                            element={
                                <Layout>
                                    <Page12 />
                                </Layout>
                            }
                        /> */}
            <Route
              path="/business-models/freelancer"
              element={
                <Layout>
                  <Freelancer />
                </Layout>
              }
            />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}
export default App;
