import AdditionalFeatures from "./AdditionalFeatures";
import AdvanceTech from "./AdvanceTech";
import BusinessContent from "./BusinessContent";
import ChooseUs from "./ChooseUs";
import CloneWork from "./CloneWork";
import "./Freelancer.scss";
import FreelanceService from "./FreelanceService";
import Impact from "./Impact";

const Freelancer = () => {
    return (
        <div className="freelancer-container">
            <FreelanceService />
            <CloneWork />
            <AdvanceTech />
            <AdditionalFeatures />
            <ChooseUs />
            <Impact />
            <BusinessContent />
        </div>
    );
};

export default Freelancer;
