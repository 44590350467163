import icons from "utils/constants/icons";
import "./ChooseUs.scss";

const ChooseUs = () => {
    const chooseList = [
        {
            image: icons?.callImage,
            title: "Dedicated Support",
            description:
                "Better opportunity for freelancers & recruiters to get noticed with dynamic profiles which are easy to update & manage",
        },
        {
            image: icons?.documentImage,
            title: "Scale With Ease",
            description:
                "Better opportunity for freelancers & recruiters to get noticed with dynamic profiles which are easy to update & manage",
        },
        {
            image: icons?.settingImage,
            title: "Fully Featured Website",
            description:
                "Better opportunity for freelancers & recruiters to get noticed with dynamic profiles which are easy to update & manage",
        },
        {
            image: icons?.learnImage,
            title: "Ease-to-use & Learn",
            description:
                "Better opportunity for freelancers & recruiters to get noticed with dynamic profiles which are easy to update & manage",
        },
        {
            image: icons?.ulIcons,
            title: "Seamless UI & UX",
            description:
                "Better opportunity for freelancers & recruiters to get noticed with dynamic profiles which are easy to update & manage",
        },
    ];
    return (
        <section className="choose-us-section">
            <div className="container">
                <h2 className="choose-title">Why Choose Us?</h2>
                <p className="choose-pra">
                    Our team of skilled professionals help transforming your
                    dreams into reality by building a Freelance app like Upwork
                    as per your requirement{" "}
                </p>
                <div className="image-div">
                    <img
                        src={icons?.sectionImage}
                        alt="section-img"
                        loading="lazy"
                    />
                </div>
                <div className="top-div">
                    <div className="row gap-div  gy-lg-5">
                        {chooseList?.map((ele) => {
                            const { description, image, title } = ele;
                            return (
                                <div className="col-xl-2 col-lg-3 col-md-6">
                                    <div className="choose-card">
                                        <div className="choose-inner-top">
                                            <img
                                                src={image}
                                                alt="choose-img"
                                                loading="lazy"
                                            />
                                            <h5 className="choose-title">
                                                {title}
                                            </h5>
                                        </div>
                                        <div className="choose-inner-last">
                                            <p className="choose-pra-text">
                                                {description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ChooseUs;
