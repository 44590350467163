import { useState } from "react";
import { NavLink } from "react-router-dom";
import icons from "utils/constants/icons";
import TouchBtn from "components/common/TouchBtn";
import "animate.css";
import "./Header.scss";

function Header() {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleItemClick = (index) => {
    if (activeDropdown === index) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(index);
    }
  };

  return (
    <>
      <div className="sub-nav">
        <div className="container">
          <div className="d-flex justify-content-end">
            <NavLink className="btn btn-hire" to="/life-sourcecube">
              Life @ Sourcecube
            </NavLink>
            <NavLink to="tel:7862091636" className="btn btn-contact pe-0">
              <img src={icons.FlagIcon} alt="Flag" className="me-2" />
              +91 78620 91636
            </NavLink>
          </div>
        </div>
      </div>
      <nav className="navbar main-nav navbar-expand-lg">
        <div className="container align-items-center">
          <NavLink className="navbar-brand" to="/">
            <img src={icons.logo} alt="Logo" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <NavLink className="navbar-brand" to="/">
                <img src={icons.logo} alt="Logo" />
              </NavLink>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body align-items-center">
              <ul className="navbar-nav nav-menu justify-content-end flex-grow-1 me-lg-2 pe-lg-4">
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <NavLink className="nav-link menu-text" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item nav-sub-menu-pera">
                  <NavLink
                    className="nav-link menu-text"
                    to="#"
                    onClick={() => handleItemClick(0)}
                  >
                    Company
                  </NavLink>
                  <ul
                    className={
                      activeDropdown === 0
                        ? "nav-sub-menu navbar-nav nav-sub-menu-pera-active"
                        : "nav-sub-menu navbar-nav"
                    }
                  >
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink to="/about" className="nav-link">
                        <img src={icons.CompanyAbout} alt="Logo" /> About US
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink to="/career" className="nav-link">
                        <img src={icons.CompanyCareer} alt="Logo" /> Career
                      </NavLink>
                    </li>
                    {/* <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close">
                      <NavLink to="/blog" className="nav-link">
                        <img src={icons.CompanyAbout} alt="Logo" /> Blog
                      </NavLink>
                    </li> */}
                  </ul>
                </li>
                <li className="nav-item nav-sub-menu-pera">
                  <NavLink
                    className="nav-link menu-text"
                    to="#"
                    onClick={() => handleItemClick(1)}
                  >
                    Services
                  </NavLink>
                  <ul
                    className={
                      activeDropdown === 1
                        ? "nav-sub-menu navbar-nav nav-sub-menu-pera-active"
                        : "nav-sub-menu navbar-nav"
                    }
                  >
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink className="nav-link">
                        <img src={icons.ServicesWeb} alt="Logo" /> Web
                        Development
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink className="nav-link">
                        <img src={icons.ServicesApp} alt="Logo" /> Mobile App
                        Solutions
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink className="nav-link">
                        <img src={icons.ServicesJs} alt="Logo" /> JS / Cloud
                        Solutions
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink className="nav-link">
                        <img src={icons.ServicesEcommerce} alt="Logo" />{" "}
                        E-commerce Solutions
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink className="nav-link">
                        <img src={icons.ServicesDevops} alt="Logo" /> Devops
                        Solutions
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item nav-sub-menu-pera">
                  <NavLink
                    className="nav-link menu-text"
                    to="#"
                    onClick={() => handleItemClick(3)}
                  >
                    Hire resources
                  </NavLink>
                  <ul
                    className={
                      activeDropdown === 3
                        ? "nav-sub-menu hire-resources-menu navbar-nav nav-sub-menu-pera-active"
                        : "nav-sub-menu hire-resources-menu navbar-nav"
                    }
                  >
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink to="/hire-developer" className="nav-link">
                        <img src={icons.dedicated} alt="Logo" />
                        Dedicated Developer
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink to="/hire-php-developer" className="nav-link">
                        <img src={icons.php} alt="Logo" />
                        Php Developer
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        to="/hire-react-js-developer"
                        className="nav-link"
                      >
                        <img src={icons.reactjs} alt="Logo" />
                        ReactJS Developer
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink to="/hire-ui-ux-developer" className="nav-link">
                        <img src={icons.figma} alt="Logo" />
                        UI/UX Developer
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        to="/hire-flutter-developer"
                        className="nav-link"
                      >
                        <img src={icons.flutter} alt="Logo" />
                        Flutter Developer
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        to="/hire-angular-developer"
                        className="nav-link"
                      >
                        <img src={icons.angular} alt="Logo" />
                        AngularJS Developer
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink to="/hire-vue-developer" className="nav-link">
                        <img src={icons.vuejs} alt="Logo" />
                        VueJS Developer
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        to="/hire-android-developer"
                        className="nav-link"
                      >
                        <img src={icons.android} alt="Logo" />
                        Android Developer
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        to="/hire-laravel-developer"
                        className="nav-link"
                      >
                        <img src={icons.laravel} alt="Logo" />
                        Laravel Developer
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink to="/hire-nodejs-developer" className="nav-link">
                        <img src={icons.nodejs} alt="Logo" />
                        NodeJS Developer
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        to="/hire-wordpress-developer"
                        className="nav-link"
                      >
                        <img src={icons.wordpress} alt="Logo" />
                        Wordpress Developer
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink to="/hire-web-designer" className="nav-link">
                        <img src={icons.webdesigner} alt="Logo" />
                        Web Designer
                      </NavLink>
                    </li>
                    {/* <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close">
                      <NavLink to="/hire-frontend-developer" className="nav-link">
                        <img src={icons.frontend} alt="Logo" />Frontend Developer
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close">
                      <NavLink to="/hire-backend-developer" className="nav-link">
                        <img src={icons.backend} alt="Logo" />Backend Developer
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close">
                      <NavLink to="/hire-fullstack-developer" className="nav-link">
                        <img src={icons.fullstack} alt="Logo" />Fullstack Developer
                      </NavLink>
                    </li> */}
                  </ul>
                </li>
                <li className="nav-item nav-sub-menu-pera">
                  <NavLink
                    className="nav-link menu-text"
                    to="#"
                    onClick={() => handleItemClick(2)}
                  >
                    Business models
                  </NavLink>
                  <ul
                    className={
                      activeDropdown === 2
                        ? "nav-sub-menu navbar-nav last-nav-menu nav-sub-menu-pera-active"
                        : "nav-sub-menu last-nav-menu navbar-nav"
                    }
                  >
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink href="#" className="nav-link">
                        <img src={icons.CompanyAbout} alt="Logo" /> Overview
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        className="nav-link"
                        // to="/business-models/page-4"
                        c
                      >
                        <img src={icons.ServicesEcommerce} alt="Logo" />{" "}
                        E-commerce Delivery
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        className="nav-link"
                        // to="/business-models/page-7"
                        // target="_blank"
                      >
                        <img src={icons.Business1} alt="Logo" /> Food Delivery
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        className="nav-link"
                        // to="/business-models/page-11"
                      >
                        <img src={icons.Business2} alt="Logo" /> Pickup &
                        Delivery
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink className="nav-link">
                        <img src={icons.Business3} alt="Logo" /> Grocery
                        Delivery
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        className="nav-link"
                        // to="/business-models/page-1"
                        // target="_blank"
                      >
                        <img src={icons.Business4} alt="Logo" /> Business Site
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink className="nav-link">
                        <img src={icons.Business5} alt="Logo" /> Taxi &
                        Transportation
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        to="/business-models/beauty-salon"
                        target="_blank"
                        className="nav-link"
                      >
                        <img src={icons.Business6} alt="Logo" /> Beauty & Salon
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        className="nav-link"
                        // to="/business-models/page-10"
                        // target="_blank"
                      >
                        <img src={icons.Business7} alt="Logo" /> Medical
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink className="nav-link">
                        <img src={icons.Business8} alt="Logo" /> Education
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        className="nav-link"
                        // to="/business-models/page-2"
                        // target="_blank"
                      >
                        <img src={icons.Business9} alt="Logo" /> Home Services
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        to="/business-models/healthcare"
                        target="_blank"
                        className="nav-link"
                      >
                        <img src={icons.Business10} alt="Logo" /> Healthcare
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        className="nav-link"
                        to="/business-models/real-estate"
                        target="_blank"
                      >
                        <img src={icons.Business11} alt="Logo" /> Real Estate
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        className="nav-link"
                        target="_blank"
                        to="/business-models/freelancer"
                      >
                        <img src={icons.Business12} alt="Logo" /> Freelancer
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink className="nav-link">
                        <img src={icons.Business13} alt="Logo" /> Fitness
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        className="nav-link"
                        to="/business-models/on-demand-app"
                        target="_blank"
                      >
                        <img src={icons.Business14} alt="Logo" /> On Demand App
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        className="nav-link"
                        // to="/business-models/page-6"
                      >
                        <img src={icons.Business15} alt="Logo" /> Social Media
                        App
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <NavLink
                        className="nav-link"
                        // to="/business-models/page-9"
                      >
                        <img src={icons.Business16} alt="Logo" /> Dating App
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
              <TouchBtn title="Get in Touch" />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
